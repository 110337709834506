export const environment = {
  production: true,
  liveAPIEndpoint: 'https://api.live.veo.co',

  oicd_authority: 'https://auth.veo.co/oidc',
  oicd_client_id: 'GfeobB5v4Vv_z7l2ejPGC',

  segmentWriteKey: 'q0IcrI7B25eymH7AZiBVIISXowoajbuM',
  disableMonitoring: false,
};
