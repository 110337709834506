import { useMutation, useQuery, useQueryClient } from 'react-query';
import { StreamEventsState, Score, Timer, StreamSummary } from '@libs/models';

import { ToastVariant, useToasts } from '@veo/web-design-system';
import { fetchFromLiveService } from './liveService';
import { useDebounce } from './shared/useDebounce';
import { useEffect, useState } from 'react';
import { getSecondsElapsed } from './stream-control/timerUtils';
import { UnauthorizedError } from './error';

const SET_TEAM_SHORT_NAME_DEBOUNCE_MS = 500;

const DEFAULT_ERROR_TOAST_PROPS = {
  variant: 'error' as ToastVariant,
  title: 'Update failed',
  description: 'Something went wrong, try again or refresh the page.',
};

function getStreamEventsStateQueryKey(streamId: string): string {
  return `streamScoreboard/${streamId}/events`;
}

export function useStreamsToEditScoreboard() {
  const { isLoading, data } = useQuery<StreamSummary[]>({
    queryKey: 'streams/to-edit-score',
    queryFn: () =>
      fetchFromLiveService<void, StreamSummary[]>('streams/to-edit-scoreboard'),
  });

  return {
    isLoading,
    streams: data || [],
  };
}

export function useSetStreamMetadata(streamId: string) {
  const { add: addToast } = useToasts();

  const {
    mutateAsync: setTeamShortNameHomeAsync,
    isLoading: isLoadingSetTeamShortNameHome,
  } = useMutation({
    mutationFn: (newName: string) =>
      fetchFromLiveService(`streams/${streamId}/events/metadata`, 'PUT', {
        teamShortName: {
          home: newName,
        },
      }),
    onError,
  });

  const {
    mutateAsync: setTeamShortNameAwayAsync,
    isLoading: isLoadingSetTeamShortNameAway,
  } = useMutation({
    mutationFn: (newName: string) =>
      fetchFromLiveService(`streams/${streamId}/events/metadata`, 'PUT', {
        teamShortName: {
          away: newName,
        },
      }),
    onError,
  });

  const {
    mutateAsync: setScoreboardVisibilityAsync,
    isLoading: isLoadingSetScoreboardVisibility,
  } = useMutation({
    mutationFn: (scoreboardVisibility: boolean) =>
      fetchFromLiveService(`streams/${streamId}/events/metadata`, 'PUT', {
        scoreboardVisibility,
      }),
    onError,
  });

  const queryClient = useQueryClient();
  const setTeamShortNameHomeDebounced = useDebounce(
    (newName: string) =>
      newName.trim().length && setTeamShortNameHomeAsync(newName),
    SET_TEAM_SHORT_NAME_DEBOUNCE_MS,
  );
  const setTeamShortNameAwayDebounced = useDebounce(
    (newName: string) =>
      newName.trim().length && setTeamShortNameAwayAsync(newName),
    SET_TEAM_SHORT_NAME_DEBOUNCE_MS,
  );

  function onError(_: unknown, __: unknown, context: { previousData: Score }) {
    addToast(DEFAULT_ERROR_TOAST_PROPS);
    queryClient.setQueryData(
      getStreamEventsStateQueryKey(streamId),
      context.previousData,
    );
  }

  function setTeamShortNameHome(newName: string) {
    queryClient.setQueryData(
      getStreamEventsStateQueryKey(streamId),
      (previousData: StreamEventsState) => ({
        ...previousData,
        streamId,
        metadata: {
          ...previousData.metadata,
          teamShortName: {
            ...previousData.metadata.teamShortName,
            home: newName,
          },
        },
      }),
    );
    setTeamShortNameHomeDebounced(newName);
  }

  function setTeamShortNameAway(newName: string) {
    queryClient.setQueryData(
      getStreamEventsStateQueryKey(streamId),
      (previousData: StreamEventsState) => ({
        ...previousData,
        streamId,
        metadata: {
          ...previousData.metadata,
          teamShortName: {
            ...previousData.metadata.teamShortName,
            away: newName,
          },
        },
      }),
    );
    setTeamShortNameAwayDebounced(newName);
  }

  function setScoreboardVisibility(isVisible: boolean) {
    queryClient.setQueryData(
      getStreamEventsStateQueryKey(streamId),
      (previousData: StreamEventsState) => ({
        ...previousData,
        streamId,
        metadata: {
          ...previousData.metadata,
          scoreboardVisible: isVisible,
        },
      }),
    );
    setScoreboardVisibilityAsync(isVisible);
  }

  return {
    setTeamShortNameHome,
    isLoadingSetTeamShortNameHome,
    setTeamShortNameAway,
    isLoadingSetTeamShortNameAway,
    setScoreboardVisibility,
    isLoadingSetScoreboardVisibility,
  };
}

export function useStreamScoreboard(streamId: string): {
  isLoading: boolean;
  scoreboard: StreamEventsState | undefined;
  secondsElapsed: number;
  unauthorized: boolean;
} {
  const { add: addToast } = useToasts();
  const [secondsElapsed, setSecondsElapsed] = useState<number>(0);
  const [unauthorized, setUnauthorized] = useState(false);
  const { isLoading, data } = useQuery<StreamEventsState>({
    queryKey: getStreamEventsStateQueryKey(streamId),
    queryFn: () =>
      fetchFromLiveService<void, StreamEventsState>(
        `streams/${streamId}/events`,
      ),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error) => {
      if (error instanceof UnauthorizedError) {
        setUnauthorized(true);
        return;
      }

      addToast(DEFAULT_ERROR_TOAST_PROPS);
    },
  });

  const timer = data?.timer;

  useEffect(() => {
    if (!timer) {
      return;
    }

    setSecondsElapsed(getSecondsElapsed(timer));
    const intervalId = setInterval(() => {
      setSecondsElapsed(getSecondsElapsed(timer));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  return {
    isLoading,
    scoreboard: data || undefined,
    secondsElapsed,
    unauthorized,
  };
}

export function useSetStreamScore(streamId: string): {
  isLoading: boolean;
  setStreamScore: (newScore: Score) => void;
} {
  const { add: addToast } = useToasts();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (newScore: Score) =>
      fetchFromLiveService(`streams/${streamId}/events/score`, 'PUT', newScore),
    onMutate: () => {
      const previousData = queryClient.getQueryData(
        getStreamEventsStateQueryKey(streamId),
      );
      return { previousData };
    },
    onError: (_, __, context: { previousData: Score }) => {
      addToast(DEFAULT_ERROR_TOAST_PROPS);
      queryClient.setQueryData(
        getStreamEventsStateQueryKey(streamId),
        context.previousData,
      );
    },
  });

  function optimisticallyUpdateStreamScoreboard(update: Score) {
    queryClient.setQueryData(
      getStreamEventsStateQueryKey(streamId),
      (previousData: StreamEventsState) => ({
        ...previousData,
        streamId,
        score: update,
        metadata: {
          ...previousData.metadata,
          scoreboardVisible: true,
        },
      }),
    );
  }

  const debounceMutateAsync = useDebounce(mutateAsync, 500);

  function setStreamScore(newScore: Score): void {
    optimisticallyUpdateStreamScoreboard(newScore);
    debounceMutateAsync(newScore);
  }

  return {
    isLoading,
    setStreamScore,
  };
}

export function useSetStreamTimer(streamId: string): {
  isLoading: boolean;
  setStreamTimer: (newTimer: Timer) => Promise<void>;
} {
  const queryClient = useQueryClient();
  const { add: addToast } = useToasts();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (newTimer: Timer) =>
      fetchFromLiveService(`streams/${streamId}/events/timer`, 'PUT', newTimer),
    onMutate: (newTimer) => {
      const previousData = queryClient.getQueryData(
        getStreamEventsStateQueryKey(streamId),
      );
      optimisticallyUpdateStreamScoreboard(newTimer);
      return { previousData };
    },
    onError: (_, __, context: { previousData: Timer }) => {
      addToast(DEFAULT_ERROR_TOAST_PROPS);
      queryClient.setQueryData(
        getStreamEventsStateQueryKey(streamId),
        context.previousData,
      );
    },
  });

  function optimisticallyUpdateStreamScoreboard(update: Timer) {
    queryClient.setQueryData(
      getStreamEventsStateQueryKey(streamId),
      (previousData: StreamEventsState) => ({
        ...previousData,
        streamId,
        timer: update,
        metadata: {
          ...previousData.metadata,
          scoreboardVisible: true,
        },
      }),
    );
  }

  async function setStreamTimer(newTimer: Timer): Promise<void> {
    await mutateAsync(newTimer);
  }

  return {
    isLoading,
    setStreamTimer,
  };
}
