import { Stream, Timer, TimerAction, TimerStart } from '@libs/models';
import { useState } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalActions,
  ModalHeader,
  Text,
} from '@veo/web-design-system';
import styles from './TimerCount.module.scss';
import inputStyles from './Input.module.scss';
import veoLogo from '../assets/veo-logo.svg';
import classNames from 'classnames';
import { getClockLabel } from './timerUtils';

const MAXIMUM_SECONDS = 60;
const MAXIMUM_MINUTES = 125;

type TimerButtonProps = {
  timer?: Timer;
  secondsElapsed: number;
  setStreamTimer: (timer: Timer) => void;
  stream?: Stream;
};

const DEFAULT_CLOCK_START_FROM = '45:00';

export function TimerCount({
  timer,
  setStreamTimer,
  stream,
  secondsElapsed,
}: TimerButtonProps) {
  const [showStartFromModal, setShowStartFromModal] = useState<boolean>(false);
  const [clockStartFrom, setClockStartFrom] = useState<string>('45:00');
  const [clockStartFromError, setClockStartFromError] = useState<
    string | undefined
  >();

  function getLabel(): string | undefined {
    if (timer?.action === 'start') {
      return 'Game live';
    }

    return 'Game paused';
  }

  function addStreamTimerAction(action: TimerAction) {
    if (action === 'start') {
      setStreamTimer({
        timestamp: Date.now(),
        action,
        offsetMs: timer ? getMsFromClockString(clockStartFrom) : 0,
      });
    }
    if (action === 'stop') {
      const currentTimer = timer as TimerStart;
      setStreamTimer({
        timestamp: Date.now(),
        action,
        previousStartTimestamp: currentTimer.timestamp,
        offsetMs: currentTimer.offsetMs,
      });
    }
  }

  function getStatusStyle() {
    if (!timer?.action) {
      return;
    }

    if (timer?.action === 'start') {
      return styles['status__live'];
    }

    return styles['status__stopped'];
  }

  function onStartTimer() {
    if (!timer) {
      addStreamTimerAction('start');
    } else {
      setShowStartFromModal(true);
    }
  }

  function onCloseStartFromModal() {
    setShowStartFromModal(false);
    setClockStartFrom(DEFAULT_CLOCK_START_FROM);
  }

  function getMsFromClockString(clockString: string): number | undefined {
    const [minutesStr, secondsStr] = clockString.split(':');
    const minutes = Number(minutesStr);
    let seconds = Number(secondsStr);

    if (
      isNaN(minutes) ||
      minutes > MAXIMUM_MINUTES ||
      seconds > MAXIMUM_SECONDS
    ) {
      return;
    }

    if (secondsStr === undefined) {
      seconds = 0;
    }

    if (isNaN(seconds)) {
      return;
    }

    return minutes * 60 * 1000 + seconds * 1000;
  }

  function onConfirmStartFrom() {
    const startFromMs = getMsFromClockString(clockStartFrom);
    if (startFromMs === undefined) {
      setClockStartFromError('Please use a valid time format, e.g. 45:00');
      return;
    }

    addStreamTimerAction('start');
    onCloseStartFromModal();
  }

  const statusStyle = getStatusStyle();

  return (
    <div className={styles['container']}>
      <Modal
        closeOnClickOutside={true}
        closeOnEscape={true}
        size="md"
        animated={true}
        open={showStartFromModal}
        onClose={onCloseStartFromModal}
      >
        <ModalHeader
          title="Start from"
          description="You have paused the stream time, input the time you’d like the stream to start from the time below."
        />
        <div className={styles['modal-body']}>
          <Input
            type="text"
            iconEnd="clock"
            placeholder={'hh:mm'}
            onChange={(event) => setClockStartFrom(event.target.value)}
            value={clockStartFrom}
            label="New time"
            className={classNames({
              [inputStyles['input-error']]: !!clockStartFromError,
            })}
          />
          {clockStartFromError && (
            <Text size={'overline'} className={inputStyles['error-message']}>
              {clockStartFromError}
            </Text>
          )}
        </div>
        <ModalActions
          onConfirm={onConfirmStartFrom}
          confirmLabel="Confirm time"
          buttonSize="md"
          showCancel={false}
        />
      </Modal>
      <div className={styles['header']}>
        <div className={styles['header-item']}>
          <Text size={'body'} className={styles['team-name']}>
            {stream?.home_team}
          </Text>
        </div>
        <div className={styles['header-item']}>
          <div className={styles['label-container']}>
            {timer?.action ? (
              <Text
                size={'caption'}
                weight={'bold'}
                className={classNames(
                  styles['label'],
                  styles['label__status'],
                  statusStyle,
                )}
              >
                {getLabel()}
              </Text>
            ) : (
              <img
                src={veoLogo}
                alt="Veo logo"
                className={styles['veo-logo']}
              />
            )}
          </div>
        </div>
        <div
          className={classNames(
            styles['header-item'],
            styles['header-item__right'],
          )}
        >
          <Text size={'body'}>{stream?.away_team}</Text>
        </div>
      </div>

      <Text
        size={'h2'}
        weight={'bold'}
        className={classNames(styles['label'], statusStyle)}
      >
        {getClockLabel(secondsElapsed)}
      </Text>

      {(!timer?.action || timer?.action !== 'start') && (
        <Button
          size={'lg'}
          iconStart={'play'}
          onClick={onStartTimer}
          label={'Start timer'}
          variant={'primary'}
        />
      )}

      {timer?.action === 'start' && (
        <Button
          size={'lg'}
          iconStart={'stop-circle'}
          label={'Stop timer'}
          variant={'primary'}
          onClick={() => addStreamTimerAction('stop')}
        />
      )}
    </div>
  );
}
