import { useQuery } from 'react-query';
import { Stream } from '@libs/models';

import { fetchFromLiveService } from './liveService';

export function useStream(streamId: string): {
  isLoading: boolean;
  stream?: Stream;
} {
  const { isLoading, data } = useQuery<Stream>({
    queryKey: `stream/${streamId}`,
    queryFn: () => fetchFromLiveService<void, Stream>(`streams/${streamId}`),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  return {
    isLoading,
    stream: data,
  };
}
