import { Timer } from '@libs/models';

export const EMPTY_CLOCK_LABEL = '--:--';

function getTwoDigitsNumber(value: number): string {
  if (value < 10) {
    return `0${value}`;
  }

  return value.toString();
}

export function getSecondsElapsed(timer: Timer): number {
  let msElapsed =
    timer.action === 'pause' ||
    timer.action === 'end' ||
    timer.action === 'stop'
      ? timer.timestamp - timer.previousStartTimestamp
      : Date.now() - timer.timestamp;

  if ('offsetMs' in timer) {
    msElapsed += timer.offsetMs || 0;
  }

  if ('pausedMs' in timer && timer.pausedMs) {
    msElapsed -= timer.pausedMs;
  }

  return Math.floor(msElapsed / 1000);
}

export function getClockLabel(secondsElapsed: number): string {
  const minutes = Math.floor(secondsElapsed / 60);
  const seconds = secondsElapsed % 60;

  return `${getTwoDigitsNumber(minutes)}:${getTwoDigitsNumber(seconds)}`;
}
